.polaroid {
  perspective: 1000px;
  width: 80%;
  height: 80%;
  cursor: pointer;
  margin: 0 auto;
  position: relative;
}

.polaroid-inner {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.8s;
}

.polaroid.flipped .polaroid-inner {
  transform: rotateY(180deg);
}

.polaroid-front, .polaroid-back {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  flex-direction: column;
}

.polaroid-front {
  padding: 2rem;
  box-sizing: border-box;
}

.polaroid-front img {
  width: 90%;
  height: 80%;
  object-fit: cover;
  border: none;
  border-radius: 0;
  margin-bottom: 1vh; 
}

.polaroid-front p {
  margin: 0;
  font-family: 'Indie Flower', cursive;
  font-size: 2vw;
  color: #666;
  text-align: center;
}

.polaroid-back {
  transform: rotateY(180deg);
  padding: 20px;
  font-family: 'Indie Flower', cursive;
  font-size: 2.5vw;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  backface-visibility: hidden;
  z-index: 2;
}

